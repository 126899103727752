<template>
    <div  >
        <div  style="cursor: pointer;width: 260px;color: #3d97d4;line-height: 50px;text-align: center;">
            <el-input
                    type="number"
                    placeholder="充值金额"
                    v-model="input"
                    clearable>
            </el-input>
            <div @click="AliPay">立即充值</div>
        </div>
        <div v-if="isShowClose == 1" style="width: 260px;height: 300px;">
            <div v-if="endTime != ''" style="text-align: center;color: red;">支付剩余时间:<count-down :endTime="endTime" :endText="endText " /></div>
            <div style="width: 200px;height: 200px;margin-left: 30px;margin-bottom: 20px;margin-top: 10px;">
                <img style="width: 100%;height: 100%;" src="~@/assets/imgs/other/closeorder.png">
            </div>
            <div style="margin-left: 50px;">
                <div @click="closeAliPayCode" style="text-align: center;cursor: pointer;color: #FF9800;float: left;margin-left: 20px;margin-right: 10px;">取消订单</div>
                <div @click="continueToOrder" style="text-align: center;cursor: pointer;color: #1495E7;float: left;">继续支付</div>
            </div>
        </div>
    </div>

</template>

<script>
    import countDown from "@/components/common/countDown";
    export default {
        name: "index",
        components:{
            countDown
        },
        data(){
            return {
                qrImgUrl:"",
                input: '',
                endTime:'',//  console.log(endTime)=>得到毫秒  1658030420 可在方法里面放入后台数据,new Date('放后台数据').getTime() / 1000 +  ' ',但要记得调用方法
                endText:'',
                msg:"请完成当前订单!",
                orderNo:"",
                isShowClose:0,
                formStr:""
            }
        },
        mounted() {
            this.checkAliPayOrderNotpaidInfo();
        },
        methods:{
            checkAliPayOrderNotpaidInfo(){
                var that = this;
                that.newApi.checkAliPayOrderNotpaidInfo().then((res)=>{
                    if(res.data.status == 100){
                        that.isShowClose = 1;
                        that.endTime = res.data.endTime;
                        that.formStr = res.data.msg;
                        that.orderNo = res.data.orderNo;
                    }
                })
            },
            AliPay(){
                var that = this;
                if(that.input == ""){
                    return that.utils.err("请输入金额");
                }
                that.getAliPayCode();
            },
            getAliPayCode() {
                var that = this;
                that.newApi.getAliPayCode({price:that.input}).then((res) => {
                    console.log(res.datnpoa);
                    document.write(res.data);
                })
            },
            continueToOrder(){
                document.write(this.formStr);
            },
            closeAliPayCode(){
                var that = this;
                that.utils.confirm("取消订单不可恢复，确认？",function () {
                    that.newApi.closeAliPayCode({orderNo:that.orderNo}).then((res) => {
                        if(res.isSuccess == 1){
                            that.isShowClose = 0;
                            that.formStr = "";
                            that.endTime = "";
                            that.orderNo = "";
                        }
                    })
                })

            }
        }
    }
</script>

<style scoped>

</style>